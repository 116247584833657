import LayoutAdmin from "@/layouts/LayoutAdmin";
import myTable from "./table";

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: "",
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listEvent: [],
      uploadRule: [(v) => !!v || "Tidak Boleh Kosong"],
      files: [],
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable();

    this.G_GetAny("sakti/event").then((res) => {
      let resData = res.data.content;

      let listData = [];
      let arrSub = {};

      resData.forEach((v) => {
        arrSub = {};

        arrSub["text"] = v.saktiEventNm;
        arrSub["value"] = v.saktiEventId;

        listData.push(arrSub);
      });

      this.listEvent = listData;
    });
  },
  components: {
    myTable,
  },
  methods: {
    getTable() {
      this.loadingTb = true;
      this.G_GetAny("user").then((res) => {
        this.loadingTb = false;
        this.tbData = res.data;
      });
    },
    store() {
      let myForm = document.getElementById("formData");
      let formData = new FormData(myForm);
      formData.set("sakti_event_id", this.$refs.sakti_event_id.getValue());

      if (this.$refs.form.validate()) {
        this.$refs.btnAct.load(true);

        this.G_PostAnyCustom("/pagu/arahan/upload/direktorat", formData)
          .then((res) => {
            console.log(res);
            if (!res.status) {
              this.$refs.sakti_event_id.setValue("");
              this.files = [];
              this.$snotify.success("Berhasil Upload Data");
            } else {
              this.$snotify.warning(res.data.message);
            }
          })
          .finally(() => {
            this.$refs.btnAct.load(false);
          });
      } else {
        this.$snotify.info("Lengkapi Data Terlebih Dahulu");
      }
    },
    downloadTemplate() {
      window.open(
        process.env.VUE_APP_URL_API + "/download/PaguArahanDirektorat.xlsx",
        "_blank"
      );
    },
    async reset(id) {
      let judul = "Reset Password";
      let subJudul = "Yakin Akan Reset Password User Ini?";

      if (await this.$refs.confirm.open(judul, subJudul)) {
        let formData = new FormData();
        formData.append("userId", id);
        this.$refs.confirm.loading();
        this.G_PostAny("user/resetPassword", formData)
          .then((res) => {
            if (res) {
              this.$refs.confirm.close();
              this.$refs.modal.close();
              this.getTable();
              this.$snotify.success(res.message);
              // this.$refs.notif.success(res.message,`Berhasil ${judul}`);
            } else {
              this.$snotify.warning(res.data.message);

              // this.$refs.notif.warning(res.message,'Terjadi Kesalahan');
            }
          })
          .finally(() => {
            this.$refs.confirm.loadingEnd();
          });
      }
    },
    setSatker(id) {
      this.G_GetRef("satker", "?balaiId=" + id).then((data) => {
        this.listStatker = data;
      });
    },
  },
};
